import axios from 'axios';

//const API_URL = 'http://localhost:8085/workplan2024';
const API_URL = 'https://confluence.philippberg.de/workplan2024';

export class APIService {

  constructor() {
    this.myUsername = localStorage.getItem('username');
    this.myPassword = localStorage.getItem('password');

    console.log("Username: " + this.myUsername + ", Password: " + this.myPassword);
  }

  /*constructor(username, password) {
      this.myUsername = localStorage.getItem('username');
      this.myPassword = localStorage.getItem('password');
  }*/

  createPerson(model) {
    const url = `${API_URL}/register`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createPersonNew(model) {
    const url = `${API_URL}/register/new`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  editPerson(model) {
    const url = `${API_URL}/register/persons/${model.id}`;
    return axios.put(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createEvent(model) {
    const url = `${API_URL}/events`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createSectionGroup(model, eventPartId) {
    const url = `${API_URL}/parts/${eventPartId}/groups`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  editSectionGroup(eventPartId, model) {
    const url = `${API_URL}/parts/${eventPartId}/groups/${model.id}`;
    return axios.put(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createSection(model, sectionGroupId) {
    const url = `${API_URL}/groups/${sectionGroupId}/sections`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  editSection(groupId, model) {
    const url = `${API_URL}/groups/${groupId}/sections/${model.id}`;
    return axios.put(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createPosition(model, sectionId) {
    const url = `${API_URL}/sections/${sectionId}/positions`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  editPosition(sectionId, model) {
    const url = `${API_URL}/sections/${sectionId}/positions/${model.id}`;
    return axios.put(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createShift(model, sectionId) {
    const url = `${API_URL}/sections/${sectionId}/shifts`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  editShift(sectionId, model) {
    const url = `${API_URL}/sections/${sectionId}/shifts/${model.id}`;
    return axios.put(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  createAssignment(model, shiftAssignmentId) {
    const url = `${API_URL}/assignments/${shiftAssignmentId}/persons`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  getPersonalWorkplanExport(personData) {
    const dUrl = `${API_URL}/persons/${personData.id}/workplan`;
    axios({
      url: dUrl,
      method: 'GET',
      responseType: 'blob',
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${personData.firstName}_${personData.lastName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }

  sendWorkplanMails(eventId) {
    const url = `${API_URL}/events/${eventId}/sendmail`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then(response => response.data);
  }

  getEventPlanExport(eventId, eventName) {
    const dUrl = `${API_URL}/events/${eventId}/workplan`;
    axios({
      url: dUrl,
      method: 'GET',
      responseType: 'blob',
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${eventName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }

  getEventPartPlanExport(eventId, partId, eventPartName) {
    const dUrl = `${API_URL}/events/${eventId}/parts/${partId}/workplan`;
    axios({
      url: dUrl,
      method: 'GET',
      responseType: 'blob',
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${eventPartName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }

  getWorkplanExportFull(modified) {
    if (modified) {
      const dUrl = `${API_URL}/persons/workplan`;
      axios({
        url: dUrl,
        method: 'GET',
        responseType: 'blob',
        params: {
          modified: true
        },
        auth: {
          username: this.myUsername,
          password: this.myPassword
        }
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `modified_helpers.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    } else {
      const dUrl = `${API_URL}/persons/workplan`;
      axios({
        url: dUrl,
        method: 'GET',
        responseType: 'blob',
        params: {
          modified: false
        },
        auth: {
          username: this.myUsername,
          password: this.myPassword
        }
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    }
  }

  getSectionGroupExport(partId, groupId, sectionGroupName) {
    const dUrl = `${API_URL}/parts/${partId}/groups/${groupId}/workplan`;
    axios({
      url: dUrl,
      method: 'GET',
      responseType: 'blob',
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${sectionGroupName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }

  getAssignmentsByShift(shiftAssignmentId) {
    const url = `${API_URL}/assignments/${shiftAssignmentId}/persons`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getAssignmentsByPart(eventId, partId) {
    const url = `${API_URL}/events/${eventId}/parts/${partId}/assignments`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getAssignments() {
    const url = `${API_URL}/assignments`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getDirtyAssignments(eventId) {
    const url = `${API_URL}/assignments/dirty`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      params: {
        eventId: eventId
      },       
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getModifiedUsers() {
    const url = `${API_URL}/persons/modified`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getAssignmentsByPerson(personId, eventPartId) {
    // we input the eventPartId although we are actually filtering for the event
    const url = `${API_URL}/persons/${personId}/assignments`;
    return axios.get(url, {
      //withCredentials: true,
      params: {
        eventPartId: eventPartId
      },            
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getPersonById(personId) {
    const url = `${API_URL}/persons/${personId}`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getAssignmentsByGroup(partId, groupId) {
    const url = `${API_URL}/parts/${partId}/groups/${groupId}/assignmentcounts`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  deleteAssignment(shiftAssignmentId, personId) {
    const url = `${API_URL}/assignments/${shiftAssignmentId}/persons/${personId}`;

    return axios.delete(url, {
      //withCredentials: true
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deleteAssignmentById(assignmentId, trace) {
    const url = `${API_URL}/assignments/${assignmentId}`;

    return axios.delete(url, {
      //withCredentials: true
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      params: {
        trace: trace
      },      
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deleteDirtyAssignmentById(dirtyAssignmentId) {
    const url = `${API_URL}/assignments/dirty/${dirtyAssignmentId}`;

    return axios.delete(url, {
      //withCredentials: true
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },     
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  resetModifiedById(personId) {
    const url = `${API_URL}/persons/modified/${personId}`;

    return axios.put(url, false, {
      //withCredentials: true     
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  getReservations() {
    const url = `${API_URL}/bookings`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getPartyRegistrations() {
    const url = `${API_URL}/partyreg`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  deleteReservation(reservationId) {
    const url = `${API_URL}/bookings/${reservationId}`;

    return axios.delete(url, {
      //withCredentials: true
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deletePartyRegistration(reservationId) {
    const url = `${API_URL}/partyreg/${reservationId}`;

    return axios.delete(url, {
      //withCredentials: true
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  getRawPersons() {
    const url = `${API_URL}/register/persons`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getPersonsFiltered(eventPartId) {
    const url = `${API_URL}/register/persons`;
    return axios.get(url, {
      params: {
        eventpartid: eventPartId
      },
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getPersonsCount(eventPartId) {
    const url = `${API_URL}/register/personscount`;
    return axios.get(url, {
      params: {
        eventpartid: eventPartId
      },
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getPersons(eventPartId, workType, filterBooked, showFlexible) {
    const url = `${API_URL}/persons`;
    return axios.get(url, {
      params: {
        eventpartid: eventPartId,
        worktype: workType,
        booked: filterBooked,
        flexible: showFlexible
      },
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getTeamMembers(personId) {
    const url = `${API_URL}/persons/${personId}/members`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getEventParts(eventId) {
    const url = `${API_URL}/events/${eventId}/parts`;
    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getEventPart(eventPartId) {
    const url = `${API_URL}/events/1/parts/${eventPartId}`;
    return axios.get(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      ,
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getSectionGroups(eventPartId) {
    const url = `${API_URL}/parts/${eventPartId}/groups`;
    return axios.get(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      ,
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getSections(sectionGroupId) {
    const url = `${API_URL}/groups/${sectionGroupId}/sections`;
    return axios.get(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      ,
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then((response) => response.data, (error) => { console.log(error) });
  }

  getEvents() {
    const url = `${API_URL}/events`;

    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then(response => response.data);
  }

  getStats() {
    const url = `${API_URL}/register/stats`;

    return axios.get(url, {
      //withCredentials: true,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).then(response => response.data);
  }

  updateShiftCount(positionId, shiftId, model) {
    const url = `${API_URL}/positions/${positionId}/shifts/${shiftId}`;
    return axios.post(url, model, {
      headers: {
        'Content-type': 'application/json',
      },
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }

  updateEvent(event) {
    const url = `${API_URL}/events/${event.id}`;

    return axios.put(url, event);
  }

  deleteEvent(event) {
    const url = `${API_URL}/events/${event.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deletePerson(person) {
    const url = `${API_URL}/register/persons/${person.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deleteSectionGroup(partId, group) {
    const url = `${API_URL}/parts/${partId}/groups/${group.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deleteSection(groupId, section) {
    const url = `${API_URL}/groups/${groupId}/sections/${section.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deletePosition(sectionId, position) {
    const url = `${API_URL}/sections/${sectionId}/positions/${position.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }

  deleteShift(sectionId, shift) {
    const url = `${API_URL}/sections/${sectionId}/shifts/${shift.id}`;

    return axios.delete(url, {
      //withCredentials: true
      auth: {
        username: this.myUsername,
        password: this.myPassword
      }
    });
  }
}